.header ul li a {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .header ul li a {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.header ul li a:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .header ul li a:hover {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
  }
}

.item-list > :not(:last-child) {
  margin-bottom: 1rem;
}

@media (width >= 768px) {
  .item-list > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

a.logo-card:hover .logo-card__image-wrapper {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.section-title:before {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
  border-radius: 9999px;
  display: block;
}

@media (prefers-color-scheme: dark) {
  .section-title:before {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
  }
}

.section-title:before {
  content: "";
  width: 6px;
  height: 6px;
}
/*# sourceMappingURL=index.2b77cf06.css.map */
